<template>
  <div class="home">
    <div class="top">
      <img src="@/assets/image/photo.png" />
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/about' }"
          >关于我们</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/common' }"
          >常见问题</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/project' }"
          >项目中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/download' }"
          >app下载</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/contact' }"
          >联系我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="head">
      <img src="@/assets/image/picture.png" />
    </div>
    <div class="background">
      <div class="front">
        <div class="advantageInfos">
          <div
            v-for="(item, index) in list"
            :key="index"
            @mouseover="mouseOver(index)"
            :class="index == curient ? 'addvantageInfo1' : 'addvantageInfo'"
          >
            <img :src="item.img" alt="" v-if="index == curient" />
            <img :src="item.imgL" alt="" v-else />

            <span>{{ item.text }}</span>
            <span class="text">{{ item.title }}</span>
            <span>{{ item.script }}</span>
            <button class="bottom" v-if="index == curient">
              {{ item.button }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <p class="written">PROJECT CENTER</p>
      <div class="advantage1">
        <div class="left">
          <p class="project">
            项目中心 专注于打造移动互联网时代的"社交电商平台+共享经济"模式
          </p>
        </div>
        <div class="right">
          <img class="pre" src="@/assets/image/10.png" />
          <img class="next" src="@/assets/image/11.png" />
        </div>
      </div>
      <div class="projectInfos">
        <div
          class="projectInfo"
          v-for="(item, index) in advantageList"
          :key="index"
        >
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="aboutMe">
      <p class="written">WHO WE ARE</p>
      <div class="aboutMe1">
        <div class="left">
          <p class="project">
            关于我们 我们专注于设计，只做一件事：持续为客户创造价值
          </p>
        </div>
        <div class="right"> -->
    <!-- 简介 -->
    <!-- </div>
      </div>
      <div class="aboutMeInfos">
        <div class="left"> -->
    <!-- 图片 -->
    <!-- <img src="" alt="" />
        </div>
        <div
          class="aboutMeInfo"
          v-for="(item, index) in aboutMeInfoList"
          :key="index"
        >
          <img src="../assets/image/14.png" alt="" />
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // imgSrc: require('@/assets/image/Shopping.png'),
      list: [
        {
          imgL: require('@/assets/image/3.png'),
          img: require('@/assets/image/money.png'),
          text: '积分商城',
          title: 'SEND HELP BUY',
          script:
            '＆nbsp ＆nbsp 平台多重渠道赠送积分，以及各类活动积分随时送，积分可兑换商城海量品牌商品，让您嗨选.选不停',
          button: '立即咨询',
        },
        {
          imgL: require('@/assets/image/5.png'),
          img: require('@/assets/image/market.png'),
          text: '跑腿服务',
          title: '369 ERRAND SERVICE',
          script:
            '369玖玖帮7*24提供安全、高效、便捷的高端跑腿服务，同城约1小时达!带排队、取快递等闪送更快更安全',
          button: '立即咨询',
        },
        {
          imgL: require('@/assets/image/7.png'),
          img: require('@/assets/image/copy.png'),
          text: '顺路约车',
          title: 'ON RAAD CAR HAILING SERVICE',
          script:
            '基于私家车主以及出租车等领域的司乘人员，一键直发行车路线，充分利用空余时间和顺路便捷顺路赚钱',
          button: '立即咨询',
        },
        {
          imgL: require('@/assets/image/9.png'),
          img: require('@/assets/image/phone.png'),
          text: '万能生活服务',
          title: 'UNIVERSAL SERVICE',
          script:
            '＆nbsp ＆nbsp个性化综合服务的生活进一步打造“万众轻资产创业、全民共享服务”的新型业态体系下融合价值的平台。',
          button: '立即咨询',
        },
      ],
      advantageList: [
        {
          img: require('@/assets/photo/fifteen.png'),
        },
        {
          img: require('@/assets/photo/seven.png'),
        },
        {
          img: require('@/assets/photo/seventeen.png'),
        },
        {
          img: require('@/assets/photo/nineteen.png'),
        },
        {
          img: require('@/assets/photo/twenty.png'),
        },
        {
          img: require('@/assets/photo/tricosa.png'),
        },
      ],
      aboutMeInfoList: [
        // { text: '专业' },
        // { text: '创新' },
        // { text: '合作' },
        // { text: '共赢' },
      ],
      curient: 5,
      // flag: false,
    }
  },
  methods: {
    mouseOver(index) {
      this.curient = index
      console.log(this.curient)
      // this.flag = true
    },
  },
}
</script>
<style  scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: 100%;
}
.top {
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
}
.top img {
  width: 300px;
  height: 50px;
  margin-left: 50px;
}
.head {
  width: 100%;
  height: 100%;
}
.background {
  width: 100%;
  height: 100vh; /**宽高100%是为了图片铺满屏幕 */
  /* z-index: -1;
  position: absolute; */
  background-image: url('../assets/image/Shopping.png');
  background-size: 100% 100%;
}
.advantage {
  width: 100%;
  height: 100vh; /**宽高100%是为了图片铺满屏幕 */
  /* z-index: -1;
  position: absolute; */
  background-image: url('../assets/image/aurora.png');
  background-size: 100% 100%;
}
.advantageInfos {
  display: flex;
  /* flex-wrap: wrap; */
  padding: 0 300px;
  justify-content: space-between;
}
.addvantageInfo {
  width: 200px;
  height: 100vh;
  display: flex;
  padding: 50px 25px 50px 25px;
  flex-direction: column;
  color: #fff;
  /* background-color: #fff; */
}
.addvantageInfo1 {
  width: 200px;
  height: 100vh;
  display: flex;
  padding: 50px 25px 50px 25px;
  flex-direction: column;
  color: #000;
  background-color: #fff;
}
.addvantageInfo img,
.addvantageInfo1 img {
  /* padding: 100px 20px; */
  width: 150px;
  height: 150px;
}
.addvantageInfo span,
.addvantageInfo1 span {
  padding-top: 25px;
  font-size: 15px;
  text-align: center;
}
.addvantageInfo1 button {
  margin-top: 35px;
  width: 150px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #003285;
  color: #003285;
}
.advantage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 300px;
}
.advantage .written {
  font-size: 45px;
  color: #fff;
}
.advantage .pre,
.advantage .next {
  width: 45px;
  height: 30px;
}
.advantage .project {
  font-size: 18px;
  color: #fff;
}
.advantage1 {
  display: flex;
  justify-content: space-between;
}
.projectInfos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.projectInfo {
  width: 30%;
  margin-right: 0;
  margin-top: 38px;
}
.aboutMe {
  width: 100%;
  height: 100vh; /**宽高100%是为了图片铺满屏幕 */
  background-image: url('../assets/image/Shopping.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 300px;
}
.aboutMeInfos {
  display: flex;
}
.aboutMeInfo {
  display: flex;
  width: 10%;
}
.aboutMeInfo img {
  position: relative;
}
.aboutMeInfo span {
  position: absolute;
  top: 1px;
}
</style>
